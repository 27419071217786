export async function exportFile(pData: { fileName: string, data: any}) {
    const url = window.URL.createObjectURL(new Blob([pData.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', pData.fileName+'.xlsx');
    document.body.appendChild(link);
    setTimeout(function() { 
        link.click();  
        // Cleanup the DOM 
        document.body.removeChild(link); 
    }, 500);
}